<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="justify-start flex-grow w-full text-yellow-50 p-2 pb-1">
        <div class="flex flex-col items-center">
          <img src="@/assets/logo-2.png" alt="" class="h-20" />

          <div class="text-2xl">Hilo bet lash number Hash</div>
          <div style="">
            <div class="relative inline-block">
              <div class="hidden sm:block">
                <!-- <div class="rounded bg-gray-700 bg-opacity-50 p-3 mt-1">
                  <div
                    class="grid grid-cols-7 gap-1 sm:grid-cols-5 lg:grid-cols-7"
                  >
                    <div v-for="item in livexbet_transactions" :key="item">
                      <center>
                        <img
                          :src="
                            '/img/cards/' +
                            item.tx_id
                              .slice(item.tx_id.length - 1)
                              .toLocaleUpperCase() +
                            '.png'
                          "
                          alt=""
                          style="max-width: 70px"
                        />

                        <a
                          class="text-xs text-gray-100"
                          target="_blink"
                          :href="'https://testnet.bscscan.com/tx/' + item.tx_id"
                        >
                          <i class="fad fa-link mr-1"></i>
                          {{ item.tx_id.slice(0, 4) }}...{{
                            item.tx_id.slice(item.tx_id.length - 4)
                          }}
                        </a>
                      </center>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-1 py-2 mx-auto sm:max-w-xl xl:max-w-full xl:max-w-screen-lg md:px-24 lg:px-8 lg:py-10"
        >
          <div class="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3">
            <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
              <table class="w-full border-collapse border border-green-800 ...">
                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(0, 6)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(6, 12)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(12, 18)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(12, 18).length - 6
                      )"
                      :key="i"
                      class="border border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(18, 24)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(18, 24).length - 6
                      )"
                      :key="i"
                      class="border w-8 h-8 border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(24, 36)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(24, 36).length - 6
                      )"
                      :key="i"
                      class="border w-8 h-8 border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="flex justify-center">
              <img src="@/assets/svg/question.svg" alt="" />
            </div>

            <div class="py-4 space-y-6 md:py-8 lg:py-12 max-w-2xl w-full">
              <table class="w-full border-collapse border border-green-800 ...">
                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(0, 6)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(6, 12)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(12, 18)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(12, 18).length - 6
                      )"
                      :key="i"
                      class="border border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(18, 24)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(18, 24).length - 6
                      )"
                      :key="i"
                      class="border w-8 h-8 border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td
                      v-for="item in livexbet_transactions.slice(24, 36)"
                      :key="item"
                      class="border border-green-600"
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <div v-bind:class="item.label">
                          <a
                            :href="
                              'https://testnet.bscscan.com/tx/' + item.tx_id
                            "
                            target="_blink"
                          >
                            {{ item.tx_id.slice(item.tx_id.length - 2) }}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td
                      v-for="i in Math.abs(
                        livexbet_transactions.slice(24, 36).length - 6
                      )"
                      :key="i"
                      class="border w-8 h-8 border-green-600 ..."
                    >
                      <div
                        class="flex flex-col items-center justify-center pt-1 pb-1"
                      >
                        <button
                          class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                          style="border-radius: 50%; w-1 h-1"
                        >
                          <span
                            class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75 w-3 h-3"
                          ></span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="w-full p-px bg-gradient-to-r from-red-700 to-green-500 rounded-3xl shadow-xl"
          >
            <div
              class="flex flex-col h-full w-full bg-dark-900 pt-3 pb-3 overflow-y-auto border-none rounded-3xl text-yellow-50 bg-gray-900"
            >
              <div class="flex">
                <div
                  class="w-3/5 w-full flex flex-col justify-center items-center"
                >
                  <div class="">
                    <div class="flex">
                      <button
                        @click="changeBet('pair')"
                        type="button"
                        :class="bg_bet.pair"
                      >
                        <i style="font-size: 30px" class="fas fa-chess"></i>
                        <br />
                        แต้มคู่
                        <br />
                        x 1.33 เท่า
                      </button>

                      <button
                        type="button"
                        @click="changeBet('high')"
                        :class="bg_bet.high"
                      >
                        <i
                          style="font-size: 20px"
                          class="fas fa-chess-queen"
                        ></i>
                        <br />
                        แต้มสูง
                        <br />
                        x 1.33 เท่า
                      </button>

                      <button
                        type="button"
                        :class="bg_bet.odd"
                        @click="changeBet('odd')"
                      >
                        <i
                          style="font-size: 20px"
                          class="fas fa-chess-bishop"
                        ></i>
                        <br />
                        แต้มคี่
                        <br />
                        x 1.33 เท่า
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex justify-center">
          <!-- <img class="w-10 m-1" src="@/assets/chip/1.webp" alt="" /> -->
          <img class="w-10 m-1" src="@/assets/chip/5.png" alt="" />
          <img class="w-10 m-1" src="@/assets/chip/10.png" alt="" />
          <img class="w-10 m-1" src="@/assets/chip/25.png" alt="" />
          <img class="w-10 m-1" src="@/assets/chip/100.png" alt="" />
          <img class="w-10 m-1" src="@/assets/chip/1000.webp" alt="" />
        </div>

        <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-sm">
          <div class="flex">
            <div class="w-3/4" style="height: 100px">
              <div class="flex flex-col">
                <div>
                  <div class="flex font-semibold mt-2">
                    <span
                      class="flex-grow leading-tight text-gray-500 text-xs sm:text-base"
                    >
                      Available Bet
                    </span>
                    <span
                      class="flex-none leading-tight whitespace-nowrap text-shadow-white text-xs sm:text-base cursor-pointer hover:text-yellow-200"
                    >
                      <span> {{ tokenBalance }} BNB</span>
                    </span>
                  </div>
                  <div class="mt-2">
                    <div
                      class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-xl"
                    >
                      <input
                        id="amount"
                        v-model="bet_amount"
                        placeholder="0"
                        class="flex-grow w-full outline-none text-lg bg-transparent"
                        type="number"
                        min="0"
                        step="0.00000001"
                      />

                      <button
                        v-if="loading"
                        class="flex-none text-sm px-2 py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
                        disabled
                      >
                        <i class="fad fa-spinner-third fa-spin m-r-xs"></i>
                        Loading
                      </button>

                      <button
                        class="w-32 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                      >
                        Bet amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-1/4">
              <div class="flex justify-center items-end" style="height: 100px">
                <button
                  @click="betHash()"
                  class="text-center bg-green-600 border-4 border-opacity-80 flex justify-center items-center"
                  style="border-radius: 50%; height: 90px; width: 90px"
                >
                  <span
                    class="animate-ping absolute inline-flex rounded-full bg-green-600 opacity-75"
                    style="height: 70px; width: 70px"
                  ></span>

                  BET
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center">
          <transactions />
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";
import {
  POOL_ADDRESS,
  POOL_ADDRESS_ODD,
  VERSION,
  EXPLORER,
} from "../../../config";

import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import user from "@/api/user";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import transactions from "../../public/transactions_bet.vue";

import Web3 from "web3";

import info from "@/api/info";

import blocks from "@/api/blocks";

const tokenABI = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],

    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    type: "function",
  },
];

export default {
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    loader,
    chameleon,
    loader,
    chameleon,
    transactions,
  },
  computed: mapGetters(["getCurrency", "getOnBlock", "getUser", "getBalance"]),

  data() {
    return {
      funds: [],
      ready: false,
      getOnBlock: this.getOnBlock,
      blocks_data: [],
      bet_detail: [],
      address: this.$route.params.address || null,
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
      explorer: EXPLORER,
      bet_amount: 0,
      bet: "",
      tokenBalance: null,
      currency: "BNB",
      chain: "bsc",
      POOL_ADDRESS,
      POOL_ADDRESS_ODD,
      bg_bet: {
        pair: "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90",
        odd: "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90",
        high: "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90",
        low: "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90",
      },
      loading: false,
      livexbet_transactions: [],
    };
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },

    async getData() {
      info.fundsummary().then((res) => {
        res.data.forEach((e) => {
          var a = label.type(e._id.type);
          e.label = a.label;

          this.funds.push(e);
        });
        this.ready = true;
      });

      await blocks
        .getTransactionOneHash()
        .then((response) => {
          this.blocks_data = response.data;
        })
        .catch((error) => {});

      await blocks
        .bethistory({ page: this.page, address: this.getUser.address })
        .then((res) => {
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            // console.log(res.data);
            res.data.forEach((e) => {
              e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

              var a = label.type(e.status);

              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });

      await info
        .livexhash()
        .then((response) => {
          // this.livexbet_transactions = response.data;

          response.data.forEach((e) => {
            var str = String(e.tx_id.slice(e.tx_id.length - 2));

            str.toLocaleLowerCase();

            if (!parseFloat(str[0])) {
              if (
                str[1] == "a" ||
                str[1] == "b" ||
                str[1] == "c" ||
                str[1] == "d" ||
                str[1] == "e" ||
                str[1] == "f"
              ) {
                e.label =
                  "bg-yellow-600 rounded-full h-6 w-6 flex flex-col items-center";

                this.livexbet_transactions.push(e);
              } else if (
                str[1] == "1" ||
                str[1] == "2" ||
                str[1] == "3" ||
                str[1] == "4" ||
                str[1] == "0"
              ) {
                e.label =
                  "bg-red-600 rounded-full h-6 w-6 flex flex-col items-center";

                this.livexbet_transactions.push(e);
              } else {
                e.label =
                  "bg-green-600 rounded-full h-6 w-6 flex flex-col items-center";

                this.livexbet_transactions.push(e);
              }
            } else {
              if (!parseFloat(str[1])) {
                if (
                  str[0] == "1" ||
                  str[0] == "2" ||
                  str[0] == "3" ||
                  str[0] == "4" ||
                  str[0] == "0"
                ) {
                  e.label =
                    "bg-red-600 rounded-full h-6 w-6 flex flex-col items-center";

                  this.livexbet_transactions.push(e);
                } else {
                  e.label =
                    "bg-green-600 rounded-full h-6 w-6 flex flex-col items-center";

                  this.livexbet_transactions.push(e);
                }
              } else if (
                str[1] == "1" ||
                str[1] == "2" ||
                str[1] == "3" ||
                str[1] == "4" ||
                str[1] == "0"
              ) {
                e.label =
                  "bg-red-600 rounded-full h-6 w-6 flex flex-col items-center";

                this.livexbet_transactions.push(e);
              } else {
                e.label =
                  "bg-green-600 rounded-full h-6 w-6 flex flex-col items-center";

                this.livexbet_transactions.push(e);
              }
            }
          });
        })
        .catch((error) => {});
    },
    async predict(p) {
      await blocks
        .bet_oneHash({
          user_id: this.getUser._id,
          amount: "0.01",
          predict: p,
          block: this.getOnBlock,
          currency: "BNB",
          address: this.getUser.address,
        })
        .then((response) => {})
        .catch((error) => {});
    },
    async get_bet_transaction() {
      await blocks
        .transactions_bet_oneHash()
        .then((response) => {
          this.bet_detail = response.data;
        })
        .catch((error) => {});
    },

    changeBet(b) {
      Object.entries(this.bg_bet).forEach((item) => {
        console.log(b, item[0], b == item[0]);

        if (b == item[0]) {
          this.bg_bet[b] =
            "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-green-200 via-green-300 to-blue-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90";
        } else {
          this.bg_bet[item[0]] =
            "w-25 rounded m-1 focus:outline-none duration-500 ease-in-out text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 transform hover:-translate-y-1 hover:scale-90";
        }
      });

      if (this.bet == b) {
        this.bet = "";
      } else {
        this.bet = b;
      }
    },
    async getTokenBalance() {
      const web3 = new Web3(ethereum);

      if (this.currency == "BNB") {
        const ether_raw = await web3.eth.getBalance(this.accounts[0]);
        this.tokenBalance = (ether_raw / Math.pow(10, 18)).toFixed(8);
      }
    },
    async getAccount() {
      this.accounts = await ethereum.request({ method: "eth_requestAccounts" });
      this.getTokenBalance();
    },

    async betHash() {
      this.loading = true;

      if (parseFloat(this.tokenBalance) >= parseFloat(this.bet_amount)) {
      } else {
        nativeToast({
          message: `The amount you entered is more than your available balance.( You have ${this.tokenBalance} ${this.currency})`,
          position: "top",
          timeout: 3000,
          type: "error",
        });
        return;
      }

      const web3 = new Web3(ethereum);

      var POOL = null;

      if (this.bet == "pair") {
        POOL = POOL_ADDRESS;
      } else {
        POOL = POOL_ADDRESS_ODD;
      }

      if (
        (this.currency == "BNB" && this.chain == "bsc") ||
        (this.currency == "ETH" && this.chain == "eth") ||
        (this.currency == "MATIC" && this.chain == "polygon")
      ) {
        this.to = POOL; // pool address
        this.value = "0x" + (this.bet_amount * Math.pow(10, 18)).toString(16);
        this.data = "0x";
      } else {
        var contract = new web3.eth.Contract(tokenABI, this.contract_address);
        this.to = this.contract_address; //contract address
        this.value = "0x0";
        this.data = contract.methods
          .transfer(
            POOL,
            (this.bet_amount * 1000000000000000000).toLocaleString("fullwide", {
              useGrouping: false,
            })
          )
          .encodeABI();
      }

      //Sending Ethereum to an address

      ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              from: this.accounts[0],
              to: this.to,
              value: this.value,
              data: this.data,
            },
          ],
        })
        .then((txHash) => {
          this.loading = false;
          this.lasttx = txHash;
          this.getTokenBalance();

          setTimeout(() => {
            this.getTokenBalance();
          }, 3500);

          nativeToast({
            message: `Tx Submitted: ${txHash.slice(0, 12)}...${txHash.slice(
              -10
            )}`,
            position: "top",
            timeout: 3000,
            type: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          nativeToast({
            message: error.message,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    this.sockets.unsubscribe("blocks");
    this.sockets.subscribe("blocks", (data) => {
      this.getOnBlock = data;
    });

    this.getData();
    this.get_bet_transaction();
    this.getAccount();
  },
  updated() {
    this.getTokenBalance();
  },
};
</script>
<style scoped></style>
